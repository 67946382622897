import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { locale } from "../../data/locale";

export const API_ROOT = process.env.REACT_APP_API_URL as string;
if (!API_ROOT) {
	console.error("REACT_APP_API_URL env variable is missing!");
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: API_ROOT,
		prepareHeaders: (headers) => {
			headers.set("Accept-Language", locale);
			headers.set("Accept", "application/json");
			return headers;
		},
	}),
	endpoints: () => ({}),
});
