import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import ErrorBoundary from "./components/ErrorBoundary";
import routes from "./routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
const News = lazy(() => import("./pages/News"));

function App() {
	return (
		<div className="App">
			<ScrollToTop />
			<Header />
			<Suspense
				fallback={
					<Box className="page" d="flex" justifyContent="center">
						<Spinner
							size="xl"
							thickness="4px"
							speed="0.65s"
							mt="8"
							emptyColor="gray.200"
							color="brand.500"
						/>
					</Box>
				}
			>
				<Switch>
					{routes.map(({ path, exact, children, Component }, i) => {
						if (children) {
							return children.map(({ path, exact, Component }, i) => (
								<Route key={i} path={path} exact={exact}>
									<ErrorBoundary>
										<Component />
									</ErrorBoundary>
								</Route>
							));
						}
						return (
							<Route key={i} path={path} exact={exact}>
								<ErrorBoundary>
									<Component />
								</ErrorBoundary>
							</Route>
						);
					})}
					<Route path="/news/:newsId">
						<News />
					</Route>
				</Switch>
			</Suspense>
			<Footer />
		</div>
	);
}
export default App;
